import React from "react"
import classnames from "classnames"

/* Import Local Styles */
import "./offsite-link.scss"

const OffsiteLink = ({
  children,
  className = "",
  href = "",
  target = "_blank",
  rel = "noreferrer",
}) => (
  <a
    className={classnames("offsite-link", className)}
    href={href}
    target={target}
    rel={rel}
  >
    <span>{children}</span>
  </a>
)

export default OffsiteLink
