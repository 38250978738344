import React, { useContext } from "react"

/* Import Global Contexts */
import UserContext from "~context/user"
import UuidContext from "~context/uuid"

/* Import Global Components */
import Page from "~components/page"
import Link from "~components/link"
import OffsiteLink from "~components/offsiteLink"
import PostPreview from "~components/postPreview"

/* Import Local Styles */
import "./user.scss"

const UserPage = () => {
  const [uuid] = useContext(UuidContext)
  const [user] = useContext(UserContext)

  return (
    <Page title="user">
      <div>
        <p className="user">UUID {uuid ? uuid.toUpperCase() : ""}</p>
      </div>
      <br />
      <div>
        <p>
          A{" "}
          <OffsiteLink href="https://en.wikipedia.org/wiki/Universally_unique_identifier">
            universally unique identifier (UUID)
          </OffsiteLink>{" "}
          is a 128-bit number used to identify information in computer systems.
        </p>
        <p>
          When generated according to the standard methods UUIDs are, for
          practical purposes, unique. Their uniqueness does not depend on a
          central registration authority or coordination between the parties
          generating them, unlike most other numbering schemes. While the
          probability that a UUID will be duplicated is not zero, it is close
          enough to zero to be negligible.
        </p>
      </div>
      <br />
      <div>
        <p>Saved Research</p>
        {user && user.research && user.research.posts && user.research.posts.length > 0 ? (
          <div>
            <p>Saved Posts</p>
            {user &&
              user.research.posts.map(post => (
                <PostPreview post={post} key={post._id} />
              ))}
          </div>
        ) : null}
        {user && user.research && user.research.authors && user.research.authors.length > 0 ? (
          <div>
            <p>Following Authors</p>
            {user &&
              user.research.authors.map(author => {
                return (
                  <div>
                    <Link to={author.slug} key={author.id}>
                      {author.name}
                    </Link>
                  </div>
                )
              })}
          </div>
        ) : null}
        {user && user.research && user.research.series && user.research.series.length > 0 ? (
          <div>
            <p>Series</p>
            {user &&
              user.research.series.map(series => {
                return (
                  <div>
                    <Link to={series.slug} key={series.id}>
                      {series.title}
                    </Link>
                  </div>
                )
              })}
          </div>
        ) : null}
      </div>
    </Page>
  )
}

export default UserPage
